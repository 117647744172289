var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-clock" },
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "人员姓名", prop: "fullName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            clearable: "",
                            "remote-method": _vm.getUserList,
                            loading: _vm.loading,
                            placeholder: "请输入人员姓名进行远程搜索",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.form.fullName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fullName", $$v)
                            },
                            expression: "form.fullName",
                          },
                        },
                        _vm._l(_vm.userList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.fullName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "人员职位" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.positionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "positionName", $$v)
                          },
                          expression: "form.positionName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属组织" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.orgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "orgName", $$v)
                          },
                          expression: "form.orgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "打卡日期", prop: "ruleDate" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "clock-time",
                        attrs: {
                          type: "date",
                          placeholder: "请选择打卡时间",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: { change: _vm.handleDateChange },
                        model: {
                          value: _vm.form.ruleDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ruleDate", $$v)
                          },
                          expression: "form.ruleDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则编码" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.ruleCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ruleCode", $$v)
                          },
                          expression: "form.ruleCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则名称" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.ruleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ruleName", $$v)
                          },
                          expression: "form.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则类型" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.ruleTypeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ruleTypeName", $$v)
                          },
                          expression: "form.ruleTypeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设置考勤状态" } },
                    [
                      _c(
                        "vxe-table",
                        { attrs: { data: _vm.clockType, align: "center" } },
                        [
                          _c("vxe-table-column", {
                            attrs: { width: "60", title: "序号" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var rowIndex = ref.rowIndex
                                  return [
                                    _c("span", [_vm._v(_vm._s(rowIndex + 1))]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("vxe-table-column", {
                            attrs: { field: "workSignType", title: "打卡类型" },
                          }),
                          _c("vxe-table-column", {
                            attrs: { title: "考勤状态" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { clearable: "" },
                                        on: { change: _vm.emitChange },
                                        model: {
                                          value: row.workSignStatus,
                                          callback: function ($$v) {
                                            _vm.$set(row, "workSignStatus", $$v)
                                          },
                                          expression: "row.workSignStatus",
                                        },
                                      },
                                      _vm._l(
                                        _vm.clockStatus,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.dictValue,
                                              value: item.dictCode,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "异常说明", prop: "exceptionRemarks" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: "4" },
                        on: { change: _vm.emitChange },
                        model: {
                          value: _vm.form.exceptionRemarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "exceptionRemarks", $$v)
                          },
                          expression: "form.exceptionRemarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }