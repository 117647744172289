<script>
import Form, { formCreate } from '../../../../../components/form';
import DetailInfo from './components/detail.vue';
import request from '../../../../../utils/request';

formCreate.component('DetailInfo', DetailInfo);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    /** @desc 设置详情的value值 */
    getDetail() {
      console.log(this.formConfig.row);
      const params = {
        id: this.formConfig.row.id,
        ruleType: this.formConfig.row.ruleType,
      };
      const requestUrl = '/sfa/sfaSignFormsController/getSignInfo';
      request.get(requestUrl, params).then((res) => {
        console.log(res);
        this.setValue({
          DetailInfo: res.result,
        });
      });
    },
  },
  async created() {
    await this.getFormRule('sfa_attendance_attendance_details_check');
    this.getDetail();
  },
};
</script>
