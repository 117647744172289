<template>
  <div class="add-clock">
    <el-form label-width="120px" :model="form" :rules="rules" ref="formRef">
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="人员姓名" prop="fullName">
            <el-select
              v-model="form.fullName"
              filterable
              remote
              clearable
              :remote-method="getUserList"
              @change="handleChange"
              :loading="loading"
              placeholder="请输入人员姓名进行远程搜索">
              <el-option
                 v-for="(item, index) in userList"
                :key="index"
                :label="item.fullName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="人员职位">
            <el-input
            v-model="form.positionName"
            disabled>
          </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所属组织">
            <el-input
              v-model="form.orgName"
              disabled>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打卡日期" prop="ruleDate">
              <el-date-picker
                v-model="form.ruleDate"
                type="date"
                placeholder="请选择打卡时间"
                class="clock-time"
                @change="handleDateChange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="规则编码">
            <el-input
              disabled
              v-model="form.ruleCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="规则名称">
            <el-input
              disabled
              v-model="form.ruleName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="规则类型">
            <el-input
              disabled
              v-model="form.ruleTypeName"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item label="设置考勤状态">
            <vxe-table :data="clockType" align="center">
              <vxe-table-column width="60" title="序号">
                <template v-slot="{rowIndex}">
                  <span>{{rowIndex+1}}</span>
                </template>
              </vxe-table-column>
              <vxe-table-column field="workSignType" title="打卡类型"></vxe-table-column>
              <vxe-table-column title="考勤状态">
                <template v-slot="{ row }">
                  <el-select clearable @change="emitChange" v-model="row.workSignStatus">
                    <el-option v-for="(item, index) in clockStatus" :key="index" :label="item.dictValue" :value="item.dictCode"></el-option>
                  </el-select>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item label="异常说明" prop="exceptionRemarks">
            <el-input
              type="textarea"
              @change="emitChange"
              v-model="form.exceptionRemarks"
              rows="4"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import request from '../../../../../../utils/request';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  props: {
    value: {
      type: Object,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val && isObject(val)) {
          this.form = val;
        }
      },
    },
  },
  data() {
    return {
      // 表单数据
      form: {
        fullName: '', // 人员姓名.
        userName: '', // 人员账号
        posCode: '', // 职位code
        positionName: '', // 人员职位
        orgCode: '', // 职位code
        orgName: '', // 所属组织
        ruleDate: '', // 打卡日期
        ruleCode: '', // 规则编码
        ruleName: '', // 规则名称
        ruleType: '', // 规则类型
        exceptionRemarks: '', // 说明
      },
      loading: false,
      // 人员列表
      userList: [],
      rules: {
        fullName: [
          { required: true, message: '请选择人员姓名', trigger: 'change' },
        ],
        ruleDate: [
          { required: true, message: '请选择打卡日期', trigger: 'change' },
        ],
        exceptionRemarks: [
          { required: true, message: '请填写异常说明', trigger: 'blur' },
        ],
      },
      // 考勤状态
      clockStatus: [],
      // 打卡类型
      clockType: [],
    };
  },
  methods: {
    // 选择数据后
    emitChange() {
      const value = {
        ...this.form,
        clockType: this.clockType,
      };
      this.$emit('input', value);
    },
    // 选择日期后
    handleDateChange() {
      this.getAddSignInfo();
      this.emitChange();
    },
    // 选择姓名后
    handleChange(val) {
      // 根据val: id 查询姓名职位组织

      const currentUser = this.userList.find((item) => item.id === val);
      if (currentUser) {
        this.form.fullName = currentUser.fullName;
        this.form.userName = currentUser.userName;
        this.form.positionCode = currentUser.positionCode; // 职位code
        this.form.positionName = currentUser.positionName;
        this.form.orgCode = currentUser.orgCode; // 组织code
        this.form.orgName = currentUser.orgName;
        // this.sfaSignTime = currentUser.sfaSignTime; // 打卡时间
      } else {
        // 清空姓名组织职位
        this.form.fullName = '';
        this.form.userName = '';
        this.form.positionCode = '';
        this.form.positionName = '';
        this.form.orgCode = '';
        this.form.orgName = '';
      }
      // 获取打卡信息
      this.getAddSignInfo();
      this.emitChange();
    },
    // 选择打卡类型后
    handleClockChange(val) {
      // 获取打卡时间
      this.form.sfaSignTime = this.clockType.find((item) => item.id === val).sfaSignTime;
    },
    // 远程搜索
    // 获取人员列表
    getUserList(query) {
      this.userList = [];
      if (query !== '') {
        this.loading = true;
        request.post('/mdm/mdmPositionController/positionSelectList', {
          unionName: query,
        }).then((res) => {
          this.loading = false;
          if (res.code === 200) {
            this.userList = res.result || [];
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.userList = [];
      }
    },
    // 获取考勤状态
    getSignStatus() {
      const requestUrl = '/mdm/mdmdictdata/list';
      request.post(requestUrl, {
        dictTypeCode: 'sign_status',
      }).then((res) => {
        this.clockStatus = res.result;
      });
    },
    // 根据人员账号、职位、组织、打卡日期获取增加考勤时的相关信息
    getAddSignInfo() {
      // 清空列表
      this.clockType = [];
      this.form.ruleCode = '';
      this.form.ruleName = '';
      this.form.ruleType = '';
      this.form.ruleTypeName = '';
      // 如果有姓名和打卡日期， 则发起请求
      if (this.form.fullName && this.form.ruleDate) {
        const requestUrl = '/sfa/sfaSignFormsController/getSignAddRecordInfo';
        const requestParam = {
          orgCode: this.form.orgCode, // 组织
          posCode: this.form.positionCode, // 人员职位
          ruleDate: this.form.ruleDate, // 打卡日期
          userName: this.form.userName, // 人员姓名
        };
        request.post(requestUrl, requestParam)
          .then((res) => {
            if (res.code === 200) {
              // 为规则名称，编码，类型赋值
              this.form.ruleCode = res.result.ruleCode;
              this.form.ruleName = res.result.ruleName;
              this.form.ruleType = res.result.ruleType;
              this.form.ruleTypeName = res.result.ruleTypeName;
              // 为打卡类型赋值
              this.clockType = res.result.sfaAddSignTypeRespVoList.map((v) => ({ ...v, workSignType: `${v.workSignTypeName} (${v.sfaSignTime})` }));
              this.emitChange();
            }
          });
      }
    },
    async submitForm() {
      const valid = await this.$refs.formRef.validate();
      if (valid) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.getSignStatus();
  },
};
</script>
<style lang="less" scoped>
  .add-clock {
    .clock-time {
      width: 100%;
    }
  }
</style>
