<template>
  <div class="margin-top-10">
      <vxe-table
        :data="tableData"
        align="center">
        <vxe-table-column field="wsUserName" title="人员编码"></vxe-table-column>
        <vxe-table-column field="wsRealName" title="人员姓名"></vxe-table-column>
        <vxe-table-column field="workSignDesc" title="打卡类型"></vxe-table-column>
        <vxe-table-column field="workSignTime" title="考勤时间"></vxe-table-column>
        <vxe-table-column field="workSignStatusDesc" title="考勤状态"></vxe-table-column>
        <vxe-table-column field="wsPlaceStatus" title="地点状态"></vxe-table-column>
        <vxe-table-column width="80" title="操作">
          <template v-slot="{ row }">
            <vxe-button type="text" @click="handleDelClick(row)" class="remove">移除</vxe-button>
          </template>
        </vxe-table-column>
      </vxe-table>
  </div>
</template>
<script>
export default {
  props: {
    // 列表数据
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val && Array.isArray(val)) {
          this.tableData = val;
        }
      },
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    // 移除异常
    handleDelClick(row) {
      this.tableData = this.tableData.filter((item) => item.id !== row.id);
      this.$emit('input', this.tableData);
    },
  },
};
</script>
<style lang="less" scoped>
  .remove {
      color: #F56C6C;
    }
</style>
