var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "margin-top-10" },
    [
      _c(
        "vxe-table",
        { attrs: { data: _vm.tableData, align: "center" } },
        [
          _c("vxe-table-column", {
            attrs: { field: "wsUserName", title: "人员编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "wsRealName", title: "人员姓名" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "workSignDesc", title: "打卡类型" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "workSignTime", title: "考勤时间" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "workSignStatusDesc", title: "考勤状态" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "wsPlaceStatus", title: "地点状态" },
          }),
          _c("vxe-table-column", {
            attrs: { width: "80", title: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "vxe-button",
                      {
                        staticClass: "remove",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelClick(row)
                          },
                        },
                      },
                      [_vm._v("移除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }