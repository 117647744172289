<script>
// eslint-disable-next-line no-unused-vars
import TablePage from '../../../../../components/table_page';
// eslint-disable-next-line no-unused-vars
import request from '../../../../../utils/request';
import Form from './form_detail.vue';
import FormClock from './form_add_clock.vue';

import FormEdit from './form_edit.vue';

import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
    FormClock,
    FormEdit, // 配置
  },
  data() {
    return {
      requestUrl: '/sfa/sfaSignFormsController/signDetail',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      options: [],
      /** @desc 搜索框绑定数据 */
      formData: {},
      searchFormData: {},
      timer: null,
      isLoading: false,
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex(
        (item) => item.field === 'yearMonth',
      );
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent(val) {
      // if (val.$event.type === 'submit') {
      //   this.$message('数据量大点击后耐心等待1min');
      //   this.isFormLoading = true;
      //   this.timer = setTimeout(() => {
      //     this.isFormLoading = false;
      //     clearTimeout(this.timer);
      //     console.log(this.timer);
      //   }, 60000);
      // }
      if (val.$event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'add_clock') this.btnAddClock(); // 新增打卡
      if (val.code === 'view') this.btnCheck(row);
      if (val.code === 'attendance_edit') {
        this.btnAttEdit(row); // 考勤异常修改
      }
    },
    cellClick({ row, column }) {
      if (column.property === 'wsRealName') {
        this.formName = 'Form';
        this.formConfig = {
          type: 'view',
          row,
        };
        this.modalConfig.title = '查看详情';
        this.openDrawer();
      }
    },
    /** @desc 新增 */
    btnAddClock() {
      this.formName = 'FormClock';
      this.formConfig.row = {};
      this.formConfig.disabled = false;
      this.modalConfig.title = '增加考勤记录';
      // this.modalConfig.showFooter = true;
      this.openFull();
    },
    /** @desc 考勤异常修改 */
    btnAttEdit(row) {
      this.formName = 'FormEdit'; // 配置
      this.formConfig.row = row;
      this.formConfig.disabled = false;
      this.modalConfig.title = '修改考勤异常信息';
      this.modalConfig.width = '900px';
      this.openModal();
    },
    // 获取打卡类型
    getType() {
      request
        .post('/sfa/sfaSignFormsController/getWorkSignType')
        .then((res) => {
          this.options = res.result;
          this.searchList.forEach((v, k) => {
            const item = v;
            if (item.field === 'workSignType') {
              this.options.forEach((a) => {
                const pItem = a;
                pItem.label = pItem.typeValue;
                pItem.value = pItem.typeKey;
              });
              this.$set(this.searchList[k].render, 'options', this.options);
              // this.$forceUpdate();
            }
          });
        });
    },
  },
  async created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    await this.getConfigList('sfa_attendance_attendance_details');
    await this.getType();
  },
};
</script>
