var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "100px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员姓名" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.wsRealName,
                      callback: function ($$v) {
                        _vm.wsRealName = $$v
                      },
                      expression: "wsRealName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账户" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.wsUserName,
                      callback: function ($$v) {
                        _vm.wsUserName = $$v
                      },
                      expression: "wsUserName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "职位" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.wsPosName,
                      callback: function ($$v) {
                        _vm.wsPosName = $$v
                      },
                      expression: "wsPosName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "区域" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.wsParentOrgName,
                      callback: function ($$v) {
                        _vm.wsParentOrgName = $$v
                      },
                      expression: "wsParentOrgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "办事处" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.wsOrgName,
                      callback: function ($$v) {
                        _vm.wsOrgName = $$v
                      },
                      expression: "wsOrgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打卡类型" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.workSignDesc,
                      callback: function ($$v) {
                        _vm.workSignDesc = $$v
                      },
                      expression: "workSignDesc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤时间" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.workSignTime,
                      callback: function ($$v) {
                        _vm.workSignTime = $$v
                      },
                      expression: "workSignTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤地点" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.workSignPlace,
                      callback: function ($$v) {
                        _vm.workSignPlace = $$v
                      },
                      expression: "workSignPlace",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.workSignStatusDesc,
                      callback: function ($$v) {
                        _vm.workSignStatusDesc = $$v
                      },
                      expression: "workSignStatusDesc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则编码" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.ruleCode,
                      callback: function ($$v) {
                        _vm.ruleCode = $$v
                      },
                      expression: "ruleCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.ruleName,
                      callback: function ($$v) {
                        _vm.ruleName = $$v
                      },
                      expression: "ruleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则类型" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.ruleTypeDesc,
                      callback: function ($$v) {
                        _vm.ruleTypeDesc = $$v
                      },
                      expression: "ruleTypeDesc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", readonly: "" },
                    model: {
                      value: _vm.remarks,
                      callback: function ($$v) {
                        _vm.remarks = $$v
                      },
                      expression: "remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "img-url", attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "照片" } },
                _vm._l(_vm.signPictures, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    attrs: { "preview-src-list": _vm.signPictures, src: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }